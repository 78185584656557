import consumer from "./consumer";

// ChatChannelのサブスクリプション作成
const chatChannel = consumer.subscriptions.create("ChatChannel", {
  connected() {
    console.log("Connected to ChatChannel");
  },

  disconnected() {
    console.log("Disconnected from ChatChannel");
  },

  received(data) {
    console.log("Received data:", data);

    var content;
    if (data.chat && data.chat.text) {
      content = data.chat.text;
/*
      var chat_text = `
        <div class="chat">
          <div class="message" data-user-id="${data.user_id}">
            <div class="chat-body">
              ${
                isUrl(content)
                  ? `<a href="${content}" target="_blank">${content}</a>`
                  : content
              }
            </div>
          </div>
          <div class="time" data-user-id="${data.user_id}">
            ${data.chat.time_current}
          </div>
        </div>
      `;
      $("#chats").append(chat_text);
*/
      } else {
      content = "New Image";
      // Photo_Taken クラスの要素に filter_saska_green を追加
      console.log("Request UUID:", data.request_uuid);
      const photoTakenElement = document.querySelector(
        `[data-request-uuid="${data.request_uuid}"] .Photo_Taken`
      );
      console.log("Photo_Taken element:", photoTakenElement);
      if (photoTakenElement) {
        photoTakenElement.classList.add("filter_saska_green");
        console.log("filter_saska_green added to Photo_Taken");
      } else {
        console.error("Photo_Taken element not found");
      }
      const newPopupContent = `
        <div class="main_package_buttons" id="${data.request_uuid}">
          <a class="open_slide_modal_right btn btn-border-shadow btn-border-shadow--color3" href="/rooms/${data.room_uuid}" data-url="/rooms/${data.room_uuid}">
            <div class="small_font">
              <i class="fas fa-solid fa-bell"></i>
              Check this out
            </div>
            A new Photo popped up!
          </a>
        </div>
      `;
      $("#new_requests_content").append(newPopupContent);
      const addedElement = $(
        "#new_requests_content .main_package_buttons"
      ).last();
      addedElement.css("animation", "slideInRight 1s ease-out");

      // クリックイベントを設定して、クリックされたリンクタグを消す
      addedElement.find("a").on("click", function (event) {
        $(this).parent().remove();
      });

    }

    const room = document.getElementById(data.chat.room_uuid);
    const newContent = `
      <div class="chat_content">
        <p>
          <span>${data.chat.text || "New Image"}</span>
        </p>
        <div class="chat_created_at">
          ${data.chat.time_current}
        </div>
      </div>
      <div class="dot_badge in_room_index"></div>
    `;

    if (room) {
      const oldContent = room.querySelector(".chat_content");
      if (oldContent) {
        oldContent.innerHTML = newContent;
      } else {
        console.error("oldContentが見つかりません: .chat_content");
      }
    } else {
      const existingElement = document.querySelector(
        `.friend[data-room-id="${data.room_uuid}"]`
      );
      if (existingElement) {
        const chatContent = existingElement.querySelector(".chat_content");
        chatContent.innerHTML = newContent;
      } else {
        const newContentHtml = `
          <a class="open_slide_modal_right" href="/rooms/${
            data.chat.room_uuid
          }" data-url="/rooms/${data.chat.room_uuid}">
            <div class="friend Editlink" data-room-id="${data.chat.room_uuid}">
              <img alt="A position of worker now" width="50" class="friend_img circle" ssl_detected="false" src="/assets/saskas/no_image.svg">
              <p>
                <strong>${data.user_name || "No name"}</strong>
              </p>
              <div id="${data.chat.room_uuid}">
                <div class="chat_content">
                  <p>
                    <span>${data.chat.text || "New Image"}</span>
                  </p>
                  <div class="chat_created_at">
                    ${data.chat.time_current}
                  </div>
                </div>
                <div class="dot_badge in_room_index"></div>
              </div>
            </div>
          </a>
        `;
        $(".friendslist").prepend(newContentHtml);
        //サンプルフレンド要素を削除
        const sampleFriend = document.querySelector(
          ".friend.light_gray_back.sample_friend"
        );
        if (sampleFriend) {
          sampleFriend.remove();
          console.log("Sample friend element removed");
        } else {
          console.error("Sample friend element not found");
        }
      }
    }

    var partnerElement = document.getElementById(data.chat.partner_uuid);
    if (partnerElement) {
      if (!partnerElement.querySelector(".dot_badge")) {
        const dotBadgeElement = document.createElement("span");
        dotBadgeElement.className = "dot_badge in_content4";
        partnerElement.appendChild(dotBadgeElement);
      }
    } else {
      console.error(`Element with id="${data.chat.partner_uuid}" not found`);
    }

    var element = document.getElementById("scrollToBottom");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  },
/*
  speak: function (data) {
    const current_user_id = $("#current_user_id").val();
    const partner_id = $("#partner_id").val();
    const partner_uuid = $("#partner_uuid").val();
    const text = $("#text").val();
    const room_id = $("#room_id").val();
    const room_uuid = $("#room_uuid").val();
    const image = $("image").val();
    const time_current = $("#time_current").val();
    return this.perform("speak", {
      text: text,
      current_user_id: current_user_id,
      partner_id: partner_id,
      partner_uuid: partner_uuid,
      room_id: room_id,
      room_uuid: room_uuid,
      image: image,
      time_current: time_current,
    });
  },
*/
});

function isUrl(str) {
  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  return regex.test(str);
}
/*
// turbo:load イベントでイベントリスナーを再適用
document.addEventListener("turbo:load", function () {
  initializeChat();
});

function initializeChat() {
  var text = document.getElementById("text");
  var button = document.getElementById("chat_register_button");
  if (text && button) {
    button.addEventListener("click", function () {
      var content = text.value;
      chatChannel.speak({
        text: content,
        room_id: text.dataset.roomId,
        current_user_id: text.dataset.currentUserId,
        partner_uuid: text.dataset.partnerUuid, // partner_uuid を追加
      });
      text.value = "";
    });
  }
}

// ページロード時に初期化
document.addEventListener("DOMContentLoaded", initializeChat);
window.chatChannel = chatChannel;
*/
