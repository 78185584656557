import consumer from "./consumer";

const qrChannel = consumer.subscriptions.create("QrChannel", {
  connected() {
    // サブスクリプションがサーバーで使用可能になったときに呼び出されます
  },

  disconnected() {
    // サブスクリプションがサーバーによって終了されたときに呼び出されます
  },

  received(data) {
    // Photo_Approved クラスの要素に filter_saska_green を追加
    const requestMergedElement = document.querySelector(
      `[data-request-uuid="${data.request_uuid}"] .Merged`
    );
    if (requestMergedElement) {
      requestMergedElement.classList.add("filter_saska_green");
    } else {
        console.log("qr_chan:",data);
    }
    const volunteerMergedElement = document.querySelector(
      `[data-request-uuid="${data.volunteer_uuid}"] .Merged`
    );
    if (volunteerMergedElement) {
      volunteerMergedElement.classList.add("filter_saska_green");
    } else {
      console.log("qr_chan:", data);
    }
    /*
    const newContent = `
            <div class="main_package_buttons" id="${data.chat.uuid}">
            <a class="open_slide_modal_right btn btn-border-shadow btn-border-shadow--color3" href="/rooms/${data.chat.room_uuid}" data-url="/rooms/${data.chat.room_uuid}">
                <div class="small_font">
                <i class="fas fa-solid fa-bell"></i>
                Check this out
                </div>
                Merged（合流完了）!
            </a>
            </div>
        `;
    const container = document.getElementById("new_requests_content");
    if (container) {
      container.insertAdjacentHTML("beforeend", newContent);
      const addedElement = container.querySelector(
        ".main_package_buttons:last-child"
      );
      addedElement.style.animation = "slideInRight 1s ease-out";

      // クリックイベントを設定して、クリックされたリンクタグを消す
      const link = addedElement.querySelector("a");
      link.addEventListener("click", function (event) {
        addedElement.remove();
        event.preventDefault(); // デフォルトの動作を防止
        this.closest(".main_package_buttons").remove();
      });
    }
    */
  },
});
