import consumer from "./consumer";

const volunteerChannel = consumer.subscriptions.create("VolunteerChannel", {
  connected() {
    console.log("Connected to VolunteerChannel");
  },

  disconnected() {
    console.log("Disconnected from VolunteerChannel");
  },

  received(data) {
    console.log("Received data:", data);

    // ボタンを無効化
    const element = document.getElementById(data.request_uuid);
    if (element) {
      element.classList.add("disable_btn");
    }

    // Recipient_Found クラスの要素に filter_saska_green を追加
    const recipientFoundElement = document.querySelector(
      `[data-request-uuid="${data.request_uuid}"] .Recipient_Found`
    );
    if (recipientFoundElement) {
      recipientFoundElement.classList.add("filter_saska_green");
    }

    // newContent を追加
    if (data.request && data.room_uuid) {
      const newContent = `
        <div class="main_package_buttons" id="${data.request.uuid}">
          <a class="open_slide_modal_right btn btn-border-shadow btn-border-shadow--color3" href="/rooms/${data.room_uuid}" data-url="/rooms/${data.room_uuid}">
            <div class="small_font">
              <i class="fas fa-solid fa-bell"></i>
              Check this out
            </div>
            Your request grabbed!
          </a>
        </div>
      `;
      const container = document.getElementById("new_requests_content");
      if (container) {
        container.insertAdjacentHTML("beforeend", newContent);
        const addedElement = container.querySelector(
          ".main_package_buttons:last-child"
        );
        addedElement.style.animation = "slideInRight 1s ease-out";

        // クリックイベントを設定して、クリックされたリンクタグを消す
        const link = addedElement.querySelector("a");
        link.addEventListener("click", function (event) {
          event.preventDefault(); // デフォルトの動作を防止
          this.closest(".main_package_buttons").remove();
        });
      }
    }

    // ボランティア数が一定数を超えた場合の処理
    if (data.request && data.request.exceeded) {
      console.log("Volunteers exceeded for request:", data.request.uuid);

      // マーカーを消す処理
      const markerId = data.request.uuid;
      console.log("Looking for marker with ID:", markerId);

      // マーカーの特定と削除
      const requestMarker = document.querySelector(
        `a[data-url="/requests/${markerId}"]`
      );
      console.log("Request marker found:", requestMarker);
      if (requestMarker) {
        const markerContainer = requestMarker.closest(".gm-style-iw");
        console.log("Marker container:", markerContainer);
        if (markerContainer) {
          markerContainer.remove();
          console.log(`Marker with id="${markerId}" removed`);
        } else {
          console.error(`Marker container for id="${markerId}" not found`);
        }
      } else {
        console.error(`Marker with id="${data.request.uuid}" not found`);
      }

      // フッターの dot_badge を消す処理
      const footerDotBadge = document.querySelector(
        ".saska_footer .content2 .dot_badge"
      );
      if (footerDotBadge) {
        footerDotBadge.remove();
        console.log("Footer dot_badge removed");
      } else {
        console.error("Footer dot_badge not found");
      }
    }
  },
});
